import {
  BackToTop,
  Button,
  ContextBar,
  Footer,
  Header,
  Logo,
} from '@vwfs-bronson/bronson-react'
import { Spinner, Wrapper } from 'cj-common-components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FooterLinks } from './components/FooterLinks'
import JourneyCompleted from './components/ActiveSaveAndContinue/journeyCompleted'
import LinkExpiry from './components/ActiveSaveAndContinue/linkExpiry'
import { HeroTeaserOpt } from './components/HeroTeaserOpt/HeroTeaserOpt'
import { TanAuthentification } from './components/TanAuthentification'
import FormScreen from './screens/Form'
import ErrorPage from './screens/Pages/error/error'
import EsignRefused from './screens/Pages/esign/esign-refused/esign-refused'
import EsignSuccessful from './screens/Pages/esign/esign-successful/esign-successful'
import pages from './screens/Pages/index.json'
import WithTransaction from './services/api/transaction/withTransaction'
import { isWorkshop } from './services/common/util'
import { history, qs } from './services/routing'

const brandName = process.env.REACT_APP_BRAND

const Lazy = props => {
  const { page, ...componentProps } = props
  const [component, setComponent] = React.useState(null)
  React.useLayoutEffect(() => {
    setComponent(null)
    setComponent(() => React.lazy(() => import(`./screens/Pages/${page}.js`)))
  }, [page])

  if (component) {
    return React.createElement(component, componentProps)
  }
  return null
}

export function App({ keycloak, keycloakLogout }) {
  const { t } = useTranslation()

  function getPageFile(pageName) {
    if (!pages.length) {
      return ''
    }
    const page = pages.find(p => p.id === pageName)
    if (!page) {
      history.push('/error')
      // eslint-disable-next-line consistent-return
      return 'error/error'
    }

    // eslint-disable-next-line consistent-return
    return page.file || ''
  }

  const id = qs(document.location.search, 'cid')

  return (
    <div className="o-root">
      <React.Fragment>
        <Wrapper pageWrapper>
          <Header
            staticHeader
            logo={<Logo ariaLabel="Logo" title="" href="/" />}
          >
            <ContextBar>
              <ContextBar.SiteNav />
              <ContextBar.SiteNav>
                {keycloak.authenticated && (
                  <Button
                    icon="brand-icon"
                    onClick={() => keycloakLogout()}
                    className="u-mr"
                  >
                    {t('page:logout')}
                  </Button>
                )}
              </ContextBar.SiteNav>
            </ContextBar>
          </Header>

          <React.Suspense
            fallback={<Spinner className="u-m-xlarge" center></Spinner>}
          >
            <Switch>
              <Route
                path="/continue"
                render={() => <TanAuthentification visible />}
              />
              <Route
                path="/page/:pageName"
                render={routeProps => (
                  <Wrapper main>
                    <Lazy
                      {...routeProps}
                      page={getPageFile(routeProps.match.params.pageName)}
                    />
                  </Wrapper>
                )}
              />
              <Route
                path="/form/:step?"
                render={routeProps => (
                  <WithTransaction>
                    <main className="o-main">
                      <FormScreen {...routeProps} config={{}} />
                    </main>
                  </WithTransaction>
                )}
              />
              <Route
                path="/error"
                render={() => (
                  <Wrapper>
                    <ErrorPage />
                  </Wrapper>
                )}
              />
              <Route
                path={['/esign-start', '/esign-ready']}
                render={routeProps => (
                  <Wrapper main>
                    <Lazy {...routeProps} page="esign/esign-landing" />
                  </Wrapper>
                )}
              />
              <Route
                path="/esign-refused"
                render={() => (
                  <Wrapper main>
                    <EsignRefused />
                  </Wrapper>
                )}
              />
              <Route
                path="/esign-successful"
                render={routeProps => (
                  <Wrapper main>
                    <EsignSuccessful {...routeProps} />
                  </Wrapper>
                )}
              />
              <Route
                path="/journey-completed"
                render={() => (
                  <Wrapper main>
                    <JourneyCompleted />
                  </Wrapper>
                )}
              />
              <Route
                path="/link-expired"
                render={() => (
                  <Wrapper main>
                    <LinkExpiry />
                  </Wrapper>
                )}
              />
              <Route
                path="/dealer"
                render={routeProps => (
                  <WithTransaction transaction={id}>
                    <HeroTeaserOpt
                      inverted={brandName === 'skoda' || brandName === 'audi'}
                      shallow={brandName === 'vw'}
                      focusPart={brandName === 'vw' ? 'top-left' : ''}
                      title={t('landingPageDealer:hero.title')}
                      sub={t('landingPageDealer:hero.subtitle')}
                      imageUrl={`${
                        process.env.PUBLIC_URL
                      }/assets/images/hero_${brandName}_w960${
                        isWorkshop && brandName === 'audi' ? '_workshop' : ''
                      }.jpg`}
                      imageSrcset={imgType => {
                        let ext = null
                        if (imgType === 'image/png') {
                          ext = 'png'
                        } else if (imgType === 'image/jpeg') {
                          ext = 'jpg'
                        } else if (imgType === 'image/webp') {
                          ext = 'webp'
                        } else {
                          return ''
                        }
                        const srcSet = `${
                          process.env.PUBLIC_URL
                        }/assets/images/hero_${brandName}_w480.${ext} 480w,
                          ${
                            process.env.PUBLIC_URL
                          }/assets/images/hero_${brandName}_w720${
                          isWorkshop && brandName === 'audi' ? '_workshop' : ''
                        }.${ext} 720w,
                          ${
                            process.env.PUBLIC_URL
                          }/assets/images/hero_${brandName}_w960${
                          isWorkshop && brandName === 'audi' ? '_workshop' : ''
                        }.${ext} 960w,
                          ${
                            process.env.PUBLIC_URL
                          }/assets/images/hero_${brandName}_w1280${
                          isWorkshop ? '_workshop' : ''
                        }.${ext} 1280w,
                          ${
                            process.env.PUBLIC_URL
                          }/assets/images/hero_${brandName}_w1920${
                          isWorkshop && brandName === 'audi' ? '_workshop' : ''
                        }.${ext} 1920w`
                        return srcSet
                      }}
                      imgSizes="100vw"
                    />
                    <Wrapper>
                      <Lazy {...routeProps} page="landing-page-dealer" />
                    </Wrapper>
                  </WithTransaction>
                )}
              />
              <Route
                exact
                path={['/', '/vehicleMismatch']}
                render={routeProps => (
                  <WithTransaction transaction={id}>
                    <HeroTeaserOpt
                      inverted={brandName === 'skoda' || brandName === 'audi'}
                      shallow={brandName === 'vw'}
                      focusPart={brandName === 'vw' ? 'top-left' : ''}
                      title={t('landingPage:hero.title')}
                      sub={t('landingPage:hero.subtitle')}
                      imageUrl={`${process.env.PUBLIC_URL}/assets/images/hero_${brandName}_w960.jpg`}
                      imageSrcset={imgType => {
                        let ext = null
                        if (imgType === 'image/png') {
                          ext = 'png'
                        } else if (imgType === 'image/jpeg') {
                          ext = 'jpg'
                        } else if (imgType === 'image/webp') {
                          ext = 'webp'
                        } else {
                          return ''
                        }
                        const srcSet = `${process.env.PUBLIC_URL}/assets/images/hero_${brandName}_w480.${ext} 480w,
                          ${process.env.PUBLIC_URL}/assets/images/hero_${brandName}_w720.${ext} 720w,
                          ${process.env.PUBLIC_URL}/assets/images/hero_${brandName}_w960.${ext} 960w,
                          ${process.env.PUBLIC_URL}/assets/images/hero_${brandName}_w1280.${ext} 1280w,
                          ${process.env.PUBLIC_URL}/assets/images/hero_${brandName}_w1920.${ext} 1920w`
                        return srcSet
                      }}
                      imgSizes="100vw"
                    />
                    <Wrapper>
                      <Lazy {...routeProps} page="landing-page" />
                    </Wrapper>
                  </WithTransaction>
                )}
              />
              <Redirect from="*" to="/error" />
            </Switch>
          </React.Suspense>

          <BackToTop>{t('page:backToTop')}</BackToTop>
          <Footer
            copyright={t('page:copyright')}
            backToTopLabel={t('page:backToTop')}
          >
            <Footer.ModuleMetaNav copyrightText={t('page:copyright')}>
              <FooterLinks />
            </Footer.ModuleMetaNav>
          </Footer>
        </Wrapper>
      </React.Fragment>
    </div>
  )
}
