import { Footer } from '@vwfs-bronson/bronson-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

export function FooterLinks() {
  const { t } = useTranslation()

  return (
    <>
      <Footer.ModuleMetaNav.Item
        key={t('footer:whoAreWe-link')}
        href={t('footer:whoAreWe-link')}
        linkProps={{ target: '_blank' }}
        normalLink
        rel="noopener noreferrer"
      >
        {t('footer:whoAreWe')}
      </Footer.ModuleMetaNav.Item>
      <Footer.ModuleMetaNav.Item
        key={t('footer:contact-link')}
        href={t('footer:contact-link')}
        linkProps={{ target: '_blank' }}
        normalLink
        rel="noopener noreferrer"
      >
        {t('footer:contact')}
      </Footer.ModuleMetaNav.Item>
      <Footer.ModuleMetaNav.Item
        key={t('footer:cookies-link')}
        href={t('footer:cookies-link')}
        linkProps={{ target: '_blank' }}
        normalLink
        rel="noopener noreferrer"
      >
        {t('footer:cookies')}
      </Footer.ModuleMetaNav.Item>
      <Footer.ModuleMetaNav.Item
        key={t('footer:faq-link')}
        href={t('footer:faq-link')}
        linkProps={{ target: '_blank' }}
        normalLink
        rel="noopener noreferrer"
      >
        {t('footer:faq')}
      </Footer.ModuleMetaNav.Item>
      <Footer.ModuleMetaNav.Item
        key={t('footer:privacyPolicy-link')}
        href={t('footer:privacyPolicy-link')}
        linkProps={{ target: '_blank' }}
        normalLink
        rel="noopener noreferrer"
      >
        {t('footer:privacyPolicy')}
      </Footer.ModuleMetaNav.Item>
      <Footer.ModuleMetaNav.Item
        key={t('footer:cgv-link')}
        href={t('footer:cgv-link')}
        linkProps={{ target: '_blank' }}
        normalLink
        rel="noopener noreferrer"
      >
        {t('footer:cgv')}
      </Footer.ModuleMetaNav.Item>
      <Footer.ModuleMetaNav.Item
        key={t('footer:legal-link')}
        href={t('footer:legal-link')}
        linkProps={{ target: '_blank' }}
        normalLink
        rel="noopener noreferrer"
      >
        {t('footer:legal')}
      </Footer.ModuleMetaNav.Item>
      <Footer.ModuleMetaNav.Item
        onClick={() => {
          if (typeof window.CookieControl?.open === 'function') {
            window.CookieControl.open()
          }
        }}
      >
        {t('cookies:footer-link')}
      </Footer.ModuleMetaNav.Item>
    </>
  )
}
