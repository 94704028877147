/* eslint-disable max-classes-per-file */
import {
  ErrorCode,
  ErrorMessage,
  EventAction,
  EventType,
  LinkInformation,
  PageName,
  TrackEventType,
  ViewChange,
  PaymentMethod,
} from './enum'

/**
 * Get form errors as a string
 *
 * Converts errors from Formik forms to a single string with a format
 * specified in analytics spec.
 * @param {*} errors Formik errors
 * @returns {string}
 */
function getFormErrorsAsString(errors) {
  return Object.entries(errors)
    .map(([key]) => `${key}`)
    .join(';')
}

export class DealerLandingPageView {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.LANDING_PAGE,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.PRODUCT_JOURNEY,
          eventAction: EventAction.START,
        },
      },
    ],
  }

  type = TrackEventType.PAGE
}

export class DealerLandingPageVehicleError {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.LANDING_PAGE,
      },
      category: {
        maturityLevel: 'Lead',
      },
      attributes: {
        viewChange: ViewChange.INPUT_ERROR,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.FORM,
          eventAction: EventAction.FIELD_ERROR,
        },
      },
    ],
    form: {
      errorFields: null,
    },
  }

  type = TrackEventType.INTERACTION

  constructor(error) {
    this.data.form.errorFields = error ? `licensePlate: ${error}` : null
  }
}

export class DealerLandingPageValidationErrors {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.LANDING_PAGE,
      },
      category: {
        maturityLevel: 'Lead',
      },
      attributes: {
        viewChange: ViewChange.INPUT_ERROR,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.FORM,
          eventAction: EventAction.FIELD_ERROR,
        },
      },
    ],
    form: {
      errorFields: null,
    },
  }

  type = TrackEventType.INTERACTION

  constructor(errors) {
    this.data.form.errorFields = getFormErrorsAsString(errors)
  }
}

export class VehicleSearchPageView {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.LANDING_PAGE,
      },
      attributes: {
        viewChange: ViewChange.VEHICLE_SEARCH,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.PAGE_VIEW,
          eventAction: EventAction.SUCCESS,
        },
      },
    ],
  }

  type = TrackEventType.PAGE
}

export class VehicleSearchInformationProvided {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.LANDING_PAGE,
      },
      attributes: {
        viewChange: ViewChange.VEHICLE_SEARCH,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.INITIAL_INFO,
          eventAction: EventAction.PROVIDED,
        },
      },
      {
        eventInfo: {
          eventType: EventType.VEHICLE_INFORMATION,
          eventAction: EventAction.PROVIDED,
        },
      },
    ],
  }

  type = TrackEventType.INTERACTION
}

export class ProductInformationProvided {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.PRODUCT_PAGE,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.PRODUCT_INFORMATION,
          eventAction: EventAction.PROVIDED,
        },
      },
    ],
  }

  type = TrackEventType.INTERACTION
}

export class PersonalInformationProvided {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.PERSONAL_PAGE,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.PERSONAL_INFORMATION,
          eventAction: EventAction.PROVIDED,
        },
      },
      {
        eventInfo: {
          eventType: EventType.REQUIRED_DATA,
          eventAction: EventAction.PROVIDED,
        },
      },
    ],
  }

  type = TrackEventType.INTERACTION

  constructor(data) {
    if (data.dealerData) {
      this.data.event.push([
        {
          eventInfo: {
            linkInformation: 'Hybrid sale',
          },
        },
      ])
    } else {
      this.data.event.push([
        {
          eventInfo: {
            linkInformation: 'Online sale',
          },
        },
      ])
    }
  }
}

export class PaymentInformationProvided {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.PAYMENT_PAGE,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.PAYMENT_DATA,
          eventAction: EventAction.PROVIDED,
        },
      },
    ],
  }

  type = TrackEventType.INTERACTION
}

export class ProductPageView {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.PRODUCT_PAGE,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.PAGE_VIEW,
          eventAction: EventAction.SUCCESS,
        },
      },
    ],
  }

  type = TrackEventType.PAGE
}

export class PersonalPageView {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.PERSONAL_PAGE,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.PAGE_VIEW,
          eventAction: EventAction.SUCCESS,
        },
      },
    ],
  }

  type = TrackEventType.PAGE
}

export class ProductFormDataError {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.PRODUCT_PAGE,
      },
      attributes: {
        viewChange: ViewChange.INPUT_ERROR,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.FORM,
          eventAction: EventAction.FIELD_ERROR,
        },
      },
    ],
    form: {
      errorFields: null,
    },
  }

  type = TrackEventType.INTERACTION

  constructor(errors) {
    this.data.form.errorFields = getFormErrorsAsString(errors)
  }
}

export class PersonalFormDataError {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.PERSONAL_PAGE,
      },
      attributes: {
        viewChange: ViewChange.INPUT_ERROR,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.FORM,
          eventAction: EventAction.FIELD_ERROR,
        },
      },
    ],
    form: {
      errorFields: null,
    },
  }

  type = TrackEventType.INTERACTION

  constructor(errors) {
    this.data.form.errorFields = getFormErrorsAsString(errors)
  }
}

export class PaymentPageView {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.PAYMENT_PAGE,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.SUMMARY_PAGE,
          eventAction: EventAction.SUCCESS,
        },
      },
    ],
  }

  type = TrackEventType.PAGE
}

export class PaymentFormDataError {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.PAYMENT_PAGE,
      },
      attributes: {
        viewChange: ViewChange.INPUT_ERROR,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.FORM,
          eventAction: EventAction.FIELD_ERROR,
        },
      },
    ],
    form: {
      errorFields: null,
    },
  }

  type = TrackEventType.INTERACTION

  constructor(errors) {
    this.data.form.errorFields = getFormErrorsAsString(errors)
  }
}

export class PaymentAPIError {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.PAYMENT_PAGE,
      },
      attributes: {
        viewChange: ViewChange.API_ERROR,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.API_ERROR,
          eventAction: EventAction.ERROR,
        },
      },
    ],
  }

  type = TrackEventType.INTERACTION

  constructor(error) {
    this.data.event[0].eventInfo = error
      ? `${EventAction.ERROR} ${JSON.stringify(error)}`
      : `${EventAction.ERROR}`
  }
}

export class SuccessPageView {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.SUCCESS_PAGE,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.PRODUCT_JOURNEY,
          eventAction: EventAction.END,
        },
      },
      {
        eventInfo: {
          eventType: EventType.SALE,
          eventAction: EventAction.SUCCESS,
        },
      },
    ],
  }

  type = TrackEventType.PAGE
}

export class SuccessPageEmailClick {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.SUCCESS_PAGE,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.INTERACTION,
          eventAction: EventAction.SUCCESS,
          linkInformation: LinkInformation.EMAIL,
        },
      },
    ],
  }

  type = TrackEventType.INTERACTION
}

export class SuccessPagePhoneClick {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.SUCCESS_PAGE,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.INTERACTION,
          eventAction: EventAction.SUCCESS,
          linkInformation: LinkInformation.PHONE,
        },
      },
    ],
  }

  type = TrackEventType.INTERACTION
}

export class SuccessPageExitToPayByPhone {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.SUCCESS_PAGE,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.INTERACTION,
          eventAction: EventAction.SUCCESS,
          linkInformation: LinkInformation.EXIT_TO_PAYBYPHONE,
        },
      },
    ],
  }

  type = TrackEventType.INTERACTION
}

export class SuccessPageExitToAssurance {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.SUCCESS_PAGE,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.INTERACTION,
          eventAction: EventAction.SUCCESS,
          linkInformation: LinkInformation.EXIT_TO_ASSURANCE,
        },
      },
    ],
  }

  type = TrackEventType.INTERACTION
}

export class SuccessPageExitToExtendedWarranty {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.SUCCESS_PAGE,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.INTERACTION,
          eventAction: EventAction.SUCCESS,
          linkInformation: LinkInformation.EXIT_TO_EXTENDED_WARRANTY,
        },
      },
    ],
  }

  type = TrackEventType.INTERACTION
}

export class SuccessPageExitToDealerSearch {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.SUCCESS_PAGE,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.INTERACTION,
          eventAction: EventAction.SUCCESS,
          linkInformation: LinkInformation.EXIT_TO_DEALER_SEARCH,
        },
      },
    ],
  }

  type = TrackEventType.INTERACTION
}

export class DocumentDownload {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.SUCCESS_PAGE,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.DOWNLOAD,
          eventAction: EventAction.REQUEST,
          linkInformation: LinkInformation.DOCUMENTS,
        },
      },
    ],
  }

  type = TrackEventType.INTERACTION
}

export class LandingPageView {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.LANDING_PAGE,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.PRODUCT_JOURNEY,
          eventAction: EventAction.START,
        },
      },
    ],
  }

  type = TrackEventType.PAGE
}

export class LandingPageValidationErrors {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.LANDING_PAGE,
      },
      attributes: {
        viewChange: ViewChange.INPUT_ERROR,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.FORM,
          eventAction: EventAction.FIELD_ERROR,
        },
      },
    ],
    form: {
      errorFields: null,
    },
  }

  type = TrackEventType.INTERACTION

  constructor(errors) {
    this.data.form.errorFields = getFormErrorsAsString(errors)
  }
}

export class LandingPageVehicleError {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.LANDING_PAGE,
      },
      attributes: {
        viewChange: ViewChange.INPUT_ERROR,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.PRODUCT_JOURNEY,
          eventAction: EventAction.ERROR,
        },
      },
    ],
    form: {
      errorFields: null,
    },
  }

  type = TrackEventType.INTERACTION

  constructor(error) {
    this.data.form.errorFields = error ? `licensePlate: ${error}` : null
  }
}

export class InitialInformationProvided {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.LANDING_PAGE,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.INITIAL_INFO,
          eventAction: EventAction.PROVIDED,
        },
      },
      {
        eventInfo: {
          eventType: EventType.VEHICLE_INFORMATION,
          eventAction: EventAction.PROVIDED,
        },
      },
    ],
  }

  type = TrackEventType.INTERACTION
}

export class CookiesPageView {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.COOKIES,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.PAGE_VIEW,
          eventAction: EventAction.SUCCESS,
        },
      },
    ],
  }

  type = TrackEventType.PAGE
}

export class ErrorPageView {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.ERROR,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.PAGE_VIEW,
          eventAction: EventAction.SUCCESS,
        },
      },
    ],
  }

  type = TrackEventType.PAGE
}

export class EsignReadyPageView {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.ESIGN_READY,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.PAGE_VIEW,
          eventAction: EventAction.SUCCESS,
        },
      },
    ],
  }

  type = TrackEventType.PAGE
}

export class EsignRefusedPageView {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.ESIGN_REFUSED,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.PAGE_VIEW,
          eventAction: EventAction.SUCCESS,
        },
      },
    ],
  }

  type = TrackEventType.PAGE
}

export class ErrorPageAPI {
  constructor(errorCausingURL, errorCode, errorMessage) {
    this.data.error.errorCausingURL = errorCausingURL
    this.data.error.errorCode = errorCode
    this.data.error.errorMessage = errorMessage
  }

  data = {
    core: {
      pageInfo: {
        pageName: PageName.ERROR_404,
      },
    },
    error: {
      errorCode: ErrorCode.ERROR_404,
      errorMessage: ErrorMessage.ERROR_404,
      errorCausingURL: null,
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.ERROR_404,
          eventAction: EventAction.ERROR_404,
        },
      },
    ],
  }

  type = TrackEventType.PAGE
}

export class ErrorPage401 {
  constructor(errorCausingURL) {
    this.data.error.errorCausingURL = errorCausingURL
  }

  data = {
    core: {
      pageInfo: {
        pageName: PageName.ERROR_401,
      },
    },
    error: {
      errorCode: ErrorCode.ERROR_401,
      errorMessage: ErrorMessage.ERROR_401,
      errorCausingURL: null,
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.ERROR_401,
          eventAction: EventAction.ERROR_401,
        },
      },
    ],
  }

  type = TrackEventType.PAGE
}

export class ErrorPage403 {
  constructor(errorCausingURL) {
    this.data.error.errorCausingURL = errorCausingURL
  }

  data = {
    core: {
      pageInfo: {
        pageName: PageName.ERROR_403,
      },
    },
    error: {
      errorCode: ErrorCode.ERROR_403,
      errorMessage: ErrorMessage.ERROR_403,
      errorCausingURL: null,
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.ERROR_403,
          eventAction: EventAction.ERROR_403,
        },
      },
    ],
  }

  type = TrackEventType.PAGE
}

export class ErrorPage404 {
  constructor(errorCausingURL) {
    this.data.error.errorCausingURL = errorCausingURL
  }

  data = {
    core: {
      pageInfo: {
        pageName: PageName.ERROR_404,
      },
    },
    error: {
      errorCode: ErrorCode.ERROR_404,
      errorMessage: ErrorMessage.ERROR_404,
      errorCausingURL: null,
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.ERROR_404,
          eventAction: EventAction.ERROR_404,
        },
      },
    ],
  }

  type = TrackEventType.PAGE
}

export class ErrorPage500 {
  constructor(errorMessage, errorCausingURL) {
    this.data.error.errorMessage = errorMessage
    this.data.error.errorCausingURL = errorCausingURL
  }

  data = {
    core: {
      pageInfo: {
        pageName: PageName.ERROR_500,
      },
    },
    error: {
      errorCode: ErrorCode.ERROR_500,
      errorMessage: null,
      errorCausingURL: null,
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.ERROR_500,
          eventAction: EventAction.ERROR_500,
        },
      },
    ],
  }

  type = TrackEventType.PAGE
}

export class LegalPageView {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.LEGAL,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.PAGE_VIEW,
          eventAction: EventAction.SUCCESS,
        },
      },
    ],
  }

  type = TrackEventType.PAGE
}

export class PrivacyPolicyPageView {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.PRIVACY_POLICY,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.PAGE_VIEW,
          eventAction: EventAction.SUCCESS,
        },
      },
    ],
  }

  type = TrackEventType.PAGE
}

export class TermsPageView {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.TERMS,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.PAGE_VIEW,
          eventAction: EventAction.SUCCESS,
        },
      },
    ],
  }

  type = TrackEventType.PAGE
}

export class LinkExpiredPageView {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.CONTINUE_LATER_EXPIRED,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.PAGE_VIEW,
          eventAction: EventAction.SUCCESS,
        },
      },
    ],
  }

  type = TrackEventType.PAGE
}

export class ContinueLaterBackClick {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.CONTINUE_LATER_EXPIRED,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.INTERACTION,
          eventAction: EventAction.SUCCESS,
          linkInformation: LinkInformation.BACK_TO_PURCHASE,
        },
      },
    ],
  }

  type = TrackEventType.INTERACTION
}

export class ContinueLaterShow {
  constructor(pageName, advice) {
    this.data.core.pageInfo.pageName = pageName
    this.data.core.attributes.viewChange = advice
      ? ViewChange.SAVE_APPLICATION_ADVICE
      : ViewChange.SAVE_APPLICATION_DEMAND
  }

  data = {
    core: {
      pageInfo: {
        pageName: null,
      },
      attributes: {
        viewChange: null,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.PAGE_VIEW,
          eventAction: EventAction.SUCCESS,
        },
      },
    ],
  }

  type = TrackEventType.PAGE
}

export class ContinueLaterSave {
  constructor(pageName, advice) {
    this.data.core.pageInfo.pageName = pageName
    this.data.event[0].eventInfo.linkInformation = advice
      ? LinkInformation.FORM_SAVED_ADVICE
      : LinkInformation.FORM_SAVED_DEMAND
  }

  data = {
    core: {
      pageInfo: {
        pageName: null,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.INTERACTION,
          eventAction: EventAction.SUCCESS,
          linkInformation: null,
        },
      },
    ],
  }

  type = TrackEventType.INTERACTION
}
export class ContinueLaterClose {
  constructor(pageName, advice) {
    this.data.core.pageInfo.pageName = pageName
    this.data.event[0].eventInfo.linkInformation = advice
      ? LinkInformation.FORM_NOT_SAVED_ADVICE
      : LinkInformation.FORM_NOT_SAVED_DEMAND
  }

  data = {
    core: {
      pageInfo: {
        pageName: null,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.INTERACTION,
          eventAction: EventAction.SUCCESS,
          linkInformation: null,
        },
      },
    ],
  }

  type = TrackEventType.INTERACTION
}

export class ContinueLaterTanReloaded {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.CONTINUE_LATER_AUTHENTICATION,
      },
      attributes: {
        viewChange: ViewChange.TAN_INPUT,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.APPLICATION_DATA,
          eventAction: EventAction.RELOADED,
        },
      },
    ],
  }

  type = TrackEventType.PAGE
}

export class ContinueLaterTanRequest {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.CONTINUE_LATER_AUTHENTICATION,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.INTERACTION,
          eventAction: EventAction.SUCCESS,
          linkInformation: LinkInformation.REQUEST_NEW_TAN,
        },
      },
    ],
  }

  type = TrackEventType.INTERACTION
}

export class ContinueLaterTanSubmitError {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.CONTINUE_LATER_AUTHENTICATION,
      },
    },
    error: {
      errorMessage: ErrorMessage.INVALID_TAN,
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.INTERACTION,
          eventAction: EventAction.SUCCESS,
          linkInformation: LinkInformation.INVALID_TAN,
        },
      },
    ],
  }

  type = TrackEventType.INTERACTION
}

export class OfferAlreadyPurchased {
  data = {
    core: {
      pageInfo: {
        pageName: PageName.CONTINUE_LATER_ALREADY_PURCHASED,
      },
      attributes: {
        viewChange: ViewChange.CONTINUE_ALREADY_PURCHASED,
      },
    },
    event: [
      {
        eventInfo: {
          eventType: EventType.PAGE_VIEW,
          eventAction: EventAction.SUCCESS,
        },
      },
    ],
  }

  type = TrackEventType.PAGE
}

export class Currency {
  data = {
    product: [
      {
        attributes: {
          currency: 'EUR',
        },
      },
    ],
  }

  type = TrackEventType.INTERACTION
}

export class PaymentFrequency {
  data = {
    product: [
      {
        attributes: {
          paymentFrequency: PaymentMethod.MONTHLY_PAYMENT,
        },
      },
    ],
  }

  type = TrackEventType.INTERACTION
}

export class RecurringPayment {
  data = {
    product: [
      {
        attributes: {
          recurringPayment: true,
        },
      },
    ],
  }

  type = TrackEventType.INTERACTION
}
