export const TrackEventType = {
  PAGE: 'page',
  INTERACTION: 'interaction',
}

export const PageName = {
  LANDING_PAGE: 'home',
  PRODUCT_PAGE: 'Product page',
  PERSONAL_PAGE: 'Personal data',
  PAYMENT_PAGE: 'Payment page',
  SUCCESS_PAGE: 'Success page',
  TERMS: 'General terms',
  COOKIES: 'Cookie policy',
  LEGAL: 'Legal',
  PRIVACY_POLICY: 'Privacy policy',
  CONTINUE_LATER_EXPIRED: 'Continue: Link expired',
  CONTINUE_LATER_AUTHENTICATION: 'Continue: Authentication',
  CONTINUE_LATER_ALREADY_PURCHASED: 'Continue: Offer already purchased',
  ERROR: 'Error',
  ERROR_401: 'Error: Unauthorized (401)',
  ERROR_403: 'Error: Access denied (403)',
  ERROR_404: 'Error: Page not found (404)',
  ERROR_500: 'Error: Internal server error (500)',
  ESIGN_REFUSED: 'E-Sign: Refused',
  ESIGN_READY: 'E-Sign: Ready',
}

export const LandingPages = {
  DEFAULT: '',
  DEALER: 'dealer',
}

export const EventType = {
  PRODUCT_JOURNEY: 'Product journey',
  INITIAL_INFO: 'Initial information',
  REQUIRED_DATA: 'RequiredContractData',
  VEHICLE_INFORMATION: 'ProductInformation:Vehicle Information',
  PRODUCT_INFORMATION: 'ProductInformation:Product Information',
  PERSONAL_INFORMATION: 'ProductInformation:Personal Information',
  PAGE_VIEW: 'pageView',
  SUMMARY_PAGE: 'SummaryPage',
  PAYMENT_DATA: 'PaymentData',
  API_ERROR: 'API error',
  DOWNLOAD: 'download',
  INTERACTION: 'interaction',
  EXIT: 'exit',
  ERROR: 'Error',
  ERROR_401: 'Login',
  ERROR_403: 'Access',
  ERROR_404: 'Error',
  ERROR_500: 'Error: Internal server error (500)',
  FORM: 'Form',
  SALE: 'Sale',
  APPLICATION_DATA: 'Application data',
}

export const EventAction = {
  SUCCESS: 'Success',
  ERROR: 'Error',
  ERROR_401: 'Unauthorized',
  ERROR_403: 'Denied',
  ERROR_404: 'Technical error',
  ERROR_500: 'Technical error',
  FIELD_ERROR: 'Field error',
  PROVIDED: 'Provided',
  REQUEST: 'request',
  TECHNICAL_ERROR: 'Technical error',
  START: 'Start',
  END: 'End',
  SAVED: 'Saved',
  RELOADED: 'Reloaded',
}

export const ViewChange = {
  VEHICLE_SEARCH: 'Vehicle search',
  TAN_INPUT: 'Continue: Authentication: TAN input',
  INPUT_ERROR: 'Input error',
  API_ERROR: 'API error',
  SAVE_APPLICATION_DEMAND: 'Save application form on demand',
  SAVE_APPLICATION_ADVICE: 'Save application form by advice',
  CONTINUE_ALREADY_PURCHASED: 'Continue: Offer already purchased',
}

export const LinkInformation = {
  DOCUMENTS: 'Download documents',
  EMAIL: 'Email',
  PHONE: 'Phone',
  EXIT_TO_DEALER_SEARCH: 'Exit to DealerSearch',
  EXIT_TO_ASSURANCE: 'Exit to assurance',
  EXIT_TO_PAYBYPHONE: 'Exit to PayByPhone',
  EXIT_TO_EXTENDED_WARRANTY: 'Exit to Extended warranty',
  BACK_TO_PURCHASE: 'Continue: Link expired: Back to purchase',
  REQUEST_NEW_TAN: 'Continue: Authentication: Request new TAN',
  INVALID_TAN: 'Continue: Authentication: Invalid TAN',
  DATA_NOT_SAVED: 'Application form not saved on demand',
  DATA_SAVED: 'Save application form on demand',
  FORM_SAVED_DEMAND: 'Application form saved on demand',
  FORM_NOT_SAVED_DEMAND: 'Application form not saved on demand',
  FORM_SAVED_ADVICE: 'Application form saved by advice',
  FORM_NOT_SAVED_ADVICE: 'Application form not saved by advice',
}

export const ErrorCode = {
  ERROR_400: '400',
  ERROR_401: '401',
  ERROR_403: '403',
  ERROR_404: '404',
  ERROR_500: '500',
}

export const ErrorMessage = {
  ERROR_400: '(400) Error',
  ERROR_401: 'Unauthorized',
  ERROR_403: 'Access denied',
  ERROR_404: 'Page not found',
  INVALID_TAN: 'Continue: Authentication: Invalid TAN',
}

export const PaymentMethod = {
  SINGLE_PAYMENT: 'Single payment',
  WEEKLY_PAYMENT: 'Weekly payment',
  BIWEEKLY_PAYMENT: 'Biweekly payment',
  MONTHLY_PAYMENT: 'Monthly payment',
  BIMONTHLY_PAYMENT: 'Bimonthly payment',
  QUARTERLY_PAYMENT: 'Quarterly payment',
  BIANNUAK_PAYMENT: 'Biannual payment',
  ANNUAL_PAYMENT: 'Annual payment',
}
